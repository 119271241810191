import React, { forwardRef, useEffect, useRef } from "react"
import { graphql, navigate } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Header from "../../components/header"
import { GatsbyImage } from "gatsby-plugin-image"
import TextWithLogo from "../../components/TextWithLogo"

import Footer from "../../components/footer"
import LinktoFormButton from "../../components/LinktoFormButton"
import Logo from "../../components/logo"

const EventTemplate = ({ data: { page } }) => {
  const elementRef = useRef()
  useEffect(() => navigate(window.location.pathname), [])

  const emailBodyLines = page.templateLines.map(line => line.lineContent).join("\n\n")

  const emailSubject = page.emailSubject || `TWO4SEVEN - ${page.title.toUpperCase()}`
  const emailBody = encodeURIComponent(emailBodyLines)

  return (
    <Layout>
      <Header className='back-tran  z-10' isLink={page.useLinkInstead} link={page.link} />
      <Seo title='Two4Seven' />
      <div data-scroll-container id='scroll-container' className='data-scroll-container'>
        <section data-scroll data-scroll-call='invertNavTran' data-scroll-repeat='true' data-scroll-offset='90%'>
          <div className='h-100-vh w-100-vw relative flex'>
            <GatsbyImage alt='' image={page.heroImage?.gatsbyImageData} className='bg-image z-2' objectFit='cover' placeholder='dominantColor' loading='eager' />
            <div className='w-100-p h-100-p z-3 absolute flex flex-center-h flex-center-v flex-c'>
              <div className='m-b-40'>
                <h1 className='c-white' id='title'>
                  {page.title}
                </h1>
              </div>
            </div>
          </div>
        </section>
        <TwoSections
          ref={elementRef}
          left={
            <TextWithLogo
              isLink={page.useLinkInstead}
              to={page.link ?? "/contact"}
              title={
                <>
                  {page.introTitle} <br /> {page.introSubtitle}
                </>
              }
              emailBody={emailBody}
              emailSubject={emailSubject}
              text={page.introParagraph}
              buttonText="I'm interested"
              buttonClass='m-mb0'
            />
          }
          right={
            <div data-scroll data-scroll-call='scale' className='h-100-p'>
              <GatsbyImage alt='' image={page.introImage?.gatsbyImageData} className='w-100-p h-100-p ' layout='fullWidth' placeholder='dominantColor' loading='lazy' />{" "}
            </div>
          }
        />
        <TwoSectionsAutoHeight left={<h3 className='text-large-orbikular m-mwa m-py40 m-h2-mobile mba mt0 max-w-9ch text-left'>{page.packageTitle}</h3>} right={<Packages packages={page.packages} />} />
        <TwoSectionsAutoHeight
          hideMobile
          left={<h3 className='text-large-orbikular m-mwa m-py40 m-h2-mobile mba mt0 max-w-9ch text-left'>{page.customiseHeading}</h3>}
          right={
            <div className='m-py40'>
              <p className='h2 max-w-17ch mb40'>{page.customiseTitle}</p>
              <p className='body-text'>{page.customiseParagraph}</p>
            </div>
          }
        />
        <section data-scroll data-scroll-call='invertNav' data-scroll-repeat='true' data-scroll-offset='80%'>
          <div className='p-t-120 p-b-80 p-t-60-br p-b-60-br  flex flex-center-h b-t-1-b bg-white flex-c m-bt0'>
            <div className='flex flex-center-h'>
              <h1 className='heading-0'>Step out of the ordinary.</h1>
            </div>
            <div className='flex flex-center-h p-t-40'>
              <LinktoFormButton emailBody={emailBody} emailSubject={emailSubject} isLink={page.useLinkInstead} to={page.link ?? "/contact"} text=' I’M INTERESTED ' className='m-b-0' />
            </div>
          </div>
        </section>
        <div data-scroll-container id='scroll-container' className='data-scroll-container' data-scroll-offset='80%'>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query EventQuery($slug: String!) {
    page: datoCmsEvent(slug: { eq: $slug }) {
      title
      heroImage {
        gatsbyImageData
      }
      introImage {
        gatsbyImageData
      }
      introTitle
      introSubtitle
      introParagraph
      packageTitle
      packages {
        id
        title
        points {
          id
          point
        }
      }
      customiseHeading
      customiseTitle
      customiseParagraph
      useLinkInstead
      link
      emailSubject
      templateLines {
        lineContent
      }
    }
  }
`

export default EventTemplate

const TwoSections = forwardRef(({ left, right }, ref) => (
  <section data-scroll data-scroll-call='invertNav' data-scroll-repeat='true' data-scroll-offset='90%'>
    <div className={` m-h-100vh-50 ma-h-100vh-50 relative  w-100-p-b flex-c flex m-wrap `}>
      <div className={`back-white  flex  w-100-p-b  relative flex-r w-100-p  h-100-p flex-grow-1 ma-h-100vh-50 m-wrap r-c-l `}>
        <div className={`w-50-p  p-40 flex flex-r al-end w-100-p-b `}>{left}</div>
        <div className={`w-50-p p-40  b-l-s-50  w-100-p-b m-bt1 m-bb1 `}>{right}</div>
      </div>
    </div>
  </section>
))

const TwoSectionsAutoHeight = ({ left, right, hideMobile }) => (
  <section data-scroll data-scroll-repeat='true' data-scroll-offset='90%' data-scroll-call='invertNav'>
    <div className={` relative  bt1 m-bt0  w-100-p-b  flex-c flex m-wrap `}>
      <div className={`back-white flex  w-100-p-b  relative flex-r w-100-p  h-100-p flex-grow-1 ma-h-100vh-50 m-wrap r-c-l `}>
        <div className={`w-50-p  p-40 flex flex-r al-end w-100-p-b ${hideMobile && "m-hide"}`}>{left}</div>
        <div className={`w-50-p p-40 b-l-s-50  w-100-p-b ${hideMobile ? "" : "m-bt1"} m-bb1 `}>{right}</div>
      </div>
    </div>
  </section>
)

const Packages = ({ packages }) => {
  return (
    <div className='columns-two m-py40 body-text tracking-wide'>
      {packages.map(item => (
        <div key={item.id}>
          <p className='h2 max-w-17ch m-h4-mobile'>{item.title}</p>
          <ul className='pr20'>
            {item.points.map(item => (
              <li key={item.id}>{item.point}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}
